import {mapMutations} from "vuex";
import mainSelect from '@/components/main-select/index.vue';
import mainButton from '@/components/main-button/index.vue'
//sections

export default {
    name: "accounts-item",
    components: {
        mainSelect,
        mainButton
    },
    props: {
        item: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {}
    },

    created() {
    },
    computed: {},

    methods: {

        ...mapMutations({})
    }
}
