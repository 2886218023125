import {mapActions, mapGetters, mapMutations} from "vuex";
import mainSelect from '@/components/main-select/index.vue';
import accountsItem from "@/modules/profile/section/accounts-item/index.vue";


import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import uk from 'vue2-datepicker/locale/uk'
import ru from 'vue2-datepicker/locale/ru'

//sections

export default {
    name: "accounts",
    components: {
        mainSelect,
        DatePicker
    },
    comments: {
        accountsItem,

    },
    data() {
        return {
            tablesIndexes: ['0'],
            selectTime: '',
            selectType: '',
            dates: null,
            lang: uk
        }
    },

    created() {
        this.getDocuments({date_from: '01-02-2022', date_to: '01-03-2023'})
        this.$route.params.lang === 'uk' ? this.lang = uk : this.lang = ru
    },
    watch: {
        date() {
            // this.getDocuments({date_from: this.date.start, date_to: this.date.end})
        }
    },
    computed: {
        ...mapGetters({
            userDocuments: 'profile/userDocuments'
        })
    },
    filters: {
        /**
         * @param {number} value
         * @return {string}
         */
        formatDocPrice(value) {
            if (value <= 0) {
                return '-'
            }

            return value.toFixed(2);
        }
    },
    methods: {
        ...mapActions({
            getDocuments: 'profile/GET_DOCUMENTS'
        }),
        isOpenedTable(...index) {
            return this.tablesIndexes.findIndex(el => el === index.join(',')) !== -1
        },
        openTable(...index) {
            let table = this.tablesIndexes.findIndex(el => el === index.join(','))
            table !== -1 ? this.tablesIndexes.splice(table, 1) : this.tablesIndexes.push(index.join(','))
        },
        openPopup(data) {
            this.isShowProfileDocument({status: true, data: data})
        },
        ...mapMutations({
            isShowProfileDocument: 'profile/SET_DOCUMENT_POPUP'
        }),
        disabledData(date) {
            let today = new Date();
            today.setDate(today.getDate() - 1);
            return date < today;
        },
    }
}
